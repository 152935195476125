import { callGptApi } from '../../../Utils/Api/Api';

/**
 * Uploads a file to OpenAI.
 * @async
 * @param {File} file - The file to upload.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while uploading the file.
 */
const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append('purpose', 'assistants');
    formData.append('file', file);
    const header = { 'Content-Type': 'multipart/form-data' };
    return await callGptApi(`/files`, 'POST', formData, header);
  } catch (error) {
    throw new Error(`Error uploading file: ${error.message}`);
  }
};


/**
 * Lists all files uploaded to OpenAI.
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while fetching files.
 */
const listFiles = async () => {
  try {
    return await callGptApi(`/files`);
  } catch (error) {
    throw new Error(`Error fetching files: ${error.message}`);
  }
};

/**
 * Deletes a file from OpenAI.
 * @async
 * @param {string} fileId - The ID of the file to delete.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while deleting the file.
 */
const deleteFile = async (fileId) => {
  try {
    return await callGptApi(`/files/${fileId}`, 'DELETE');
  } catch (error) {
    throw new Error(`Error deleting file: ${error.message}`);
  }
};

export { uploadFile, listFiles, deleteFile };
