import { callGptApi } from '../../../Utils/Api/Api';

/**
 * Creates a new thread.
 * @async
 * @param {string} role - The role of the message sender.
 * @param {string} content - The content of the message.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while creating the thread.
 */
const createThread = async (role, content) => {
  try {
    const response = await callGptApi('/threads', 'POST', {
      messages: [{ role, content }],
    });
    return response;
  } catch (error) {
    throw new Error(`Error creating thread: ${error.message}`);
  }
};

/**
 * Sends a message in a thread.
 * @async
 * @param {string} threadId - The ID of the thread.
 * @param {string} [role='user'] - The role of the message sender.
 * @param {string} content - The content of the message.
 * @param {Object} [metadata={}] - Additional metadata for the message.
 * @param {{}} [attachments=[]] - The attachments for the message.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 */
const sendMessage = async (
  threadId,
  content,
  metadata = {},
  role = 'user',
  attachments = []
) => {
  try {
    const requestBody = {
      role,
      content,
      metadata,
      attachments,
    };
    const response = await callGptApi(
      `/threads/${threadId}/messages`,
      'POST',
      requestBody,
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error sending message: ${error.message}`);
  }
};

/**
 * Creates a new run.
 * @async
 * @param {string} threadId - The ID of the thread.
 * @param {string} assistantId - The ID of the assistant.
 * @param stream
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while creating the run.
 */
const createRun = async (threadId, assistantId, stream = false) => {
  try {
    const response = await callGptApi(`/threads/${threadId}/runs`, 'POST', {
      assistant_id: assistantId,
      stream: stream,
    });
    return response;
  } catch (error) {
    throw new Error(`Error creating run: ${error.message}`);
  }
};

/**
 * Lists messages in a thread.
 * @async
 * @param {string} threadId - The ID of the thread.
 * @returns {Promise<Array<Object>>} An array of message objects.
 * @throws {Error} If an error occurs while listing messages.
 */
const listMessages = async (threadId) => {
  try {
    const response = await callGptApi(`/threads/${threadId}/messages`);
    return response.data.map((message) => ({
      id: message.id,
      role: message.role,
      text: message.content[0].text.value, // Assuming there's only one text content
      fileIds: message.file_ids,
      metadata: message.metadata,
    }));
  } catch (error) {
    throw new Error(`Error listing messages: ${error.message}`);
  }
};

/**
 * Lists messages in a thread.
 * @async
 * @param {string} threadId - The ID of the thread.
 * @returns {Promise<Object>} An array of message objects.
 * @throws {Error} If an error occurs while listing messages.
 */
const listPromptMessages = async (threadId) => {
  try {
    const response = await callGptApi(`/threads/${threadId}/messages`);
    return response;
  } catch (error) {
    throw new Error(`Error listing messages: ${error.message}`);
  }
};
/**
 * Retrieves details of a specific run.
 * @async
 * @param {string} threadId - The ID of the thread.
 * @param {string} runId - The ID of the run.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while retrieving the run.
 */
const retrieveRun = async (threadId, runId) => {
  try {
    const response = await callGptApi(`/threads/${threadId}/runs/${runId}`);
    return response;
  } catch (error) {
    throw new Error(`Error retrieving run: ${error.message}`);
  }
};
// Define a function to parse and handle the streaming data
const handleOpenAIStream = async (stream, setMessages) => {
  const reader = stream.body.getReader(); // Get a reader for the stream
  let partialMessage = ''; // Store partial message content if received in chunks
  while (true) {
    const { done, value } = await reader.read(); // Read from the stream

    if (done) {
      break;
    }

    // Convert the chunk of data to string
    const chunk = new TextDecoder().decode(value);

    // Append the chunk to the partial message
    partialMessage += chunk;

    // Split the received data into individual events
    const events = partialMessage.split('\n\n');

    // Process each event
    for (let i = 0; i < events.length - 1; i++) {
      const event = events[i];

      // Parse the event data
      const eventData = event.split('\n').reduce((acc, line) => {
        const [key, value] = line.split(': ').map(str => str.trim());
        acc[key] = value;
        return acc;
      }, {});

      // Check for the done event
      if (eventData.event === 'done' && eventData.data === '[DONE]') {
        return; // Exit the function to stop processing
      }
      // Extract relevant data from the event
      const data = JSON.parse(eventData.data);
      if (eventData.event === 'thread.message.delta') {
        const messageContent = data.delta.content.map(content => content.text.value).join('');
        setMessages(prevMessages => {
          const lastMessageIndex = prevMessages.length - 1;
          if (prevMessages[lastMessageIndex]?.role === 'assistant') {
            // If the last message is from the assistant, append the stream message to it
            const updatedMessages = [...prevMessages];
            updatedMessages[lastMessageIndex].text += messageContent;
            return updatedMessages;
          } else {
            // If the last message is not from the assistant, add a new message
            return [...prevMessages, { role: 'assistant', text: messageContent }];
          }
        });
      }
    }

    // Save any remaining partial message for the next iteration
    partialMessage = events[events.length - 1];
  }
};

export { retrieveRun, listMessages, listPromptMessages, createThread, sendMessage, createRun, handleOpenAIStream };
