import { SmartRagApiCall } from '../../../Utils/Api/Api';

/**
 * Creates a new chat session.
 * @async
 * @param {string} tenantId - The Tenant ID.
 * @param {Object} chatDetails - The details for creating a chat session.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while creating the chat session.
 */
const sendQuestion = async (tenantId, chatDetails) => {
  try {
    const headers = {
      'Tenant-ID': tenantId,
      'Content-Type': 'application/json',
    };
    const response = await SmartRagApiCall(`/chat`, 'POST', chatDetails, headers);

    return {
      ...response,
      message: response.message || (response.isSuccess ? 'Chat session created successfully!' : 'An error occurred while creating the chat session.'),
    };
  } catch (error) {
    throw new Error(`Error creating chat session: ${error.message}`);
  }
};

/**
 * Streams a response to a given question in a chat session.
 * @async
 * @param {string} tenantId - The Tenant ID.
 * @param {Object} data - The data to send for streaming the response.
 * @param {Function} setMessages - Function to update chat messages.
 * @returns {Promise<void>} Streams the response.
 * @throws {Error} If an error occurs while streaming the response.
 */
const handleSmartRagStream = async (tenantId, data, setMessages) => {
  const url = '/chat'; // Adjusted to use relative path for SmartAIApiCall
  const headers = {
    'Tenant-ID': tenantId,
    'Content-Type': 'application/json',
  };

  try {
    // Use SmartAIApiCall to make the request with streaming enabled
    const response = await SmartRagApiCall(url, 'POST', data, headers, true);

    if (!response.isSuccess) {
      throw new Error(response.message);
    }

    const reader = response.data.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let buffer = '';
    let combinedMessage = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      buffer += decoder.decode(value, { stream: true });

      // Process complete JSON objects from the buffer
      let start = buffer.indexOf('{');
      let end = buffer.indexOf('}', start);

      while (start !== -1 && end !== -1 && end > start) {
        let jsonString = buffer.substring(start, end + 1);

        // Handle JSON with single quotes
        const fixedJsonString = jsonString.replace(/'/g, '"');

        try {
          // Check if the JSON contains "citations" and skip if necessary
          if (fixedJsonString.includes('"citations"')) {
            // Skip processing citations and move to the next potential JSON object
            buffer = buffer.substring(end + 1);
            start = buffer.indexOf('{');
            end = buffer.indexOf('}', start);
            continue;
          }

          // Parse JSON and handle different message types
          const parsedMessage = JSON.parse(fixedJsonString);

          if (parsedMessage.answer) {
            // Append answer to combinedMessage
            combinedMessage += parsedMessage.answer;

            // Update messages with the combined answer
            setMessages(prevMessages => {
              const lastMessageIndex = prevMessages.length - 1;
              if (prevMessages[lastMessageIndex]?.role === 'assistant') {
                const updatedMessages = [...prevMessages];
                updatedMessages[lastMessageIndex].text = combinedMessage; // Update the combined message
                return updatedMessages;
              } else {
                return [...prevMessages, { role: 'assistant', text: combinedMessage }];
              }
            });
          } else if (parsedMessage.citations) {
            // Handle citations if needed
            console.log('Citations:', parsedMessage.citations);
            // Process or store citations as required
          }

        } catch (error) {
        }

        // Update buffer to process remaining data
        buffer = buffer.substring(end + 1);
        start = buffer.indexOf('{');
        end = buffer.indexOf('}', start);
      }
    }
  } catch (error) {
    throw new Error(`Error streaming response: ${error.message}`);
  }
};

export { sendQuestion, handleSmartRagStream };
