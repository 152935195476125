import { BYApiCall } from '../../../Utils/Api/Api';

/**
 * Send a message and get the reply.
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param data
 */
const sendBYMessage = async (data) => {
  try {
    const response = await BYApiCall('/warehouse/chat', 'POST', data);
    return response;
  } catch (error) {
    return { message: 'There is some thing wrong Please try again!' };
  }
};

export default sendBYMessage;
